import "../libs/import-defaults"
import carouselTemplate from "./carousel.html"
import posts from "../portfolio/imgs/posts"
import autoGenerateIndicators from "../libs/carousel";
import portfolio from "../portfolio/index.html";

$(document).ready(async () =>{
  const carouselTemplateData = await $.get(carouselTemplate).promise();

  // add new carousel template (HTML)
  $("#containerCarousel").append(carouselTemplateData);

  // populate with posts
  posts.forEach((post, index) => {
    const itemDivClass = index === 0 ? "carousel-item active" : "carousel-item";
    const carouselItem = `<div class="${itemDivClass}">
            <a href="${portfolio}?post=${index}">
                <img src="${post.imgs[0]}" class="mx-auto d-block img-fluid" alt="${post.title}">
                <div class="carousel-caption">
                    <h5>${post.title}</h5>
                    <p>Clique na imagem para ver mais detalhes</p>
                </div>
            </a>
        </div>`;
    $("#carouselInner").append(carouselItem);
  });

  autoGenerateIndicators();
});